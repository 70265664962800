import React,{useState,useRef,useCallback,useEffect, memo} from 'react';
import WithToast from '../api/hooks/withtoasthook';
import Select, { components } from 'react-select';
import { countrystyle, getMaskedEmail, handleApiError, process_params, selectWidth, validateAndSetMobileChange } from '../api/utils/utils';
import axiosInstance from '../api/utils/axiosutils';
import eyeslash from '../images/eye_slash-01.png';
import eye from '../images/eye-01.png';
import eyegrey from '../images/eye-01pass.png';
import Modal from 'react-bootstrap/Modal';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import { countryselectapi, signupapi, signupverifyapi } from '../api/helper/urlhelper';
import { useCountryData } from '../api/hooks/mobilehook';
import useMenuHeight from '../api/hooks/useMenuheight';
import expand from '../images/expand icon-02.svg';
const countryselect = countryselectapi();
const signupapiurl = signupapi();
const signupverifyapiurl = signupverifyapi();
const Signup = memo(({showErrorToast,signupclick,setIsLoading4,myaccountdomainurl})=>{
  const [inlineerror,setInlineError] = useState({
    passworderror:'',
    nameerror : '',
    passwordcurrenterror : '',
    emailerror : '',
    mobileerror:''
  });
  const [ischecked,setIsChecked] = useState('');
  const [enabledverify,setEnabledverify] = useState(false);
  const [enabledsignup,setEnabledsignup] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const mobileInputRef = useRef(null);
  const [countdown, setCountdown] = useState(120);
  const [showPasswordnew , setShowPasswordnew] = useState(false);
  const [otp, setOtp] = useState('');
  const [show1,setShow1] = useState(false);
  const [showPasswordcur , setShowPasswordcur] = useState(false);
  const [mobileChange, setMobileChange] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
    const {
      mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
      loadCountryByIp,
    } = useCountryData();
  const [formValues,setFormValues] = useState({
    name:'',email:'',mobile:'',mcc_id:'',country:null,countryloading:false,countryoption:[],countryOptions:[],isLoading3:false,dial_code:'',country_code:'',passwordnew:'',passwordcurrent:''
  })
  const [error422,setError422] = useState('');
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
const countryList = useCallback(async () => {
  if(formValues.countryoption.length > 0) return;
    try {
      setFormValues(prev => ({ ...prev, countryLoading: true }));
      const response = await axiosInstance.get(countryselect);
      if (response.status === 200) {
        const countryList = response.data.data;
        setFormValues(prev => ({
          ...prev,
          countryoption: countryList.map(company => ({ value: company.id, label: company.name }))
        }));
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    } finally {
      setFormValues(prev => ({ ...prev, countryLoading: false }));
    }
}, [showErrorToast,formValues.countryoption.length,]);

  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      <p className="customsinglep">{`+${data.dial_code}`}</p>
    </div>
);
const customOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginRight: '10px' }}
          />
          {props.data.label}
        </div>
      </components.Option>
    );
};
const ip = useCallback(async()=>{
  if(signupclick){
  loadCountryByIp(true);
}
},[signupclick])
useEffect(() => { 
 ip();
}, [ip]);
useEffect(()=>{
  if(signupclick){
    countryList();
  }
},[countryList,signupclick]);
 const handleKeyPress1 = (e) => {
  const char = String.fromCharCode(e.which);
  if (!/^[a-zA-Z ]$/.test(char)) {
    e.preventDefault();
    setInlineError((prevState) => ({
      ...prevState,
      nameerror: 'Only alphabetic characters and spaces are allowed',
    }));
  } else {
    setInlineError((prevState) => ({
      ...prevState,
      nameerror: '',
    }));
  }
};
const handlecountry = (selectedOption) => {
  setFormValues((prev)=>({
    ...prev,
    country:selectedOption
  }));
  setError422('');
}
const customStyles1 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'46px',
    fontSize:'14px',
    borderRadius:'.25rem',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),container: (provided) => ({
    ...provided,
    width:'100%',
    maxWidth:'446px',
    borderRadius:'.25rem',
    marginTop:'.5rem', 
  }),placeholder: (provided) => ({
    ...provided,
    // color: '#ABABAB',
    width: '100%',
    fontSize:'12px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'red',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
const customStyles2 = {
  control: (provided) => ({
    ...provided,
    width:formValues.isLoading3 ? '30px' : `${selwidth}px` ,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
    display: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  menu: (provided) => ({
    ...provided,
    width: '216px',
  }),
}
const handleCountryChangesign = (selectedOption) => {
  const selectedCountryData = countryOptions.find(
    (country) => country.value === selectedOption.value
);
if (selectedCountryData) {
  const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;

  setMccId(mcc_id);
  setDialCode(`+${dial_code}`);
  setCountryCode(country_code);
  setMobile_length(mobile_length);
  setMobile_starting_digits(mobile_starting_digits);

  // Apply validation to existing mobile number
  validateAndSetMobileChange(mobileChange, {
    setMobileChange,
    setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
    setNumberInvalid: setNumberinvalid,
    mobile_length,
    mobile_starting_digits,
  });
}
  setError422('');
};

const handlePaste = (e) => {
  const pastedText = e.clipboardData.getData('text');
  let numericPastedText = pastedText.replace(/[^0-9]/g, '');

  // Remove leading '0' if present
  if (numericPastedText.startsWith('0')) {
      numericPastedText = numericPastedText.slice(1);
  }

  const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
  if (numericPastedText.length > maxLength) {
      numericPastedText = numericPastedText.slice(0, maxLength);
  }

  setFormValues((prev)=>({
      ...prev,username:numericPastedText
  }));
  setError422('');
  e.preventDefault();
};
const handleKeyPresssign = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
const handlePhoneNumberChangesign = (value) => {
  validateAndSetMobileChange(value, {
    setMobileChange: setMobileChange,
    setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
    setNumberInvalid: setNumberinvalid,
    mobile_length: mobile_length,             
    mobile_starting_digits: mobile_starting_digits 
  });
  setError422('')
};
const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
const menuHeight = useMenuHeight(200, 400);
useEffect(() => {
  setSelWidth(selectWidth(dialCode));
}, [dialCode]);
const [isInvalid, setIsInvalid] = useState({
  letter: true,
  capital: true,
  special: true,
  number: true,
  length: true,
});
const handlepasswordchange = (event) => {
  const newPasswords = event.target.value;
  setFormValues((prev)=>({
    ...prev,passwordnew:newPasswords
  }));
  const lowercaseLetters = /[a-z]/g;
  const uppercaseLetters = /[A-Z]/g;
  const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]/g;
  const numbers = /[0-9]/g;
  setIsInvalid({
      letter: !newPasswords.match(lowercaseLetters),
      capital: !newPasswords.match(uppercaseLetters),
      special: !newPasswords.match(specialCharacters),
      number: !newPasswords.match(numbers),
      length: newPasswords.length < 8,

  });
  setInlineError((prev)=>({
    ...prev,passworderror:''
  }))
}
const handleFocus = () => {
    setIsFocused(true);
};
const getPasswordValidationClass = (isValid) => {
    return isValid ? 'valid' : 'invalid';
};
const handleBlur = () => {
    setIsFocused(false);
};
const togglePasswordVisibilitynew = () => {
  setShowPasswordnew(!showPasswordnew);
}
const togglePasswordVisibilitycur = () => {
  setShowPasswordcur(!showPasswordcur);
}
const handleKeyDown = (e) => {
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault(); // Prevent the default action
    setError422('Please enter only numbers'); // Set error message
  } else {
    setError422(''); // Clear error if valid key is pressed
  }
};
const checksign = useCallback(()=>{
  const valid = (
    formValues.name !== '' && formValues.email !== '' && mobileChange !== '' && formValues.country !== '' && formValues.passwordnew !== '' && formValues.passwordcurrent !== '' && numberinvalid === true && ischecked === true
  )
  setEnabledsignup(valid);
},[ formValues.name,formValues.email,mobileChange,formValues.country,formValues.passwordnew,formValues.passwordcurrent,numberinvalid,ischecked]);
useEffect(()=>{
  checksign();
},[checksign])
const signup = async(e) => {
  e.preventDefault();
      if (formValues.passwordnew !== formValues.passwordcurrent) {
        setInlineError((prevState) => ({
          ...prevState,
          passwordcurrenterror: 'Passwords do not match',
        }));
        return false;
       }else{
        setInlineError((prevState) => ({
          ...prevState,
          passwordcurrenterror: '',
        }));
       }
       const allFalse = Object.values(isInvalid).every(value => value === false); 
      if(!allFalse){
        setInlineError((prevState) => ({
          ...prevState,
          passworderror: 'Password invalid',
        }));
        return false;
      }else{
        setInlineError((prevState) => ({
          ...prevState,
          passworderror: '',
        }));
      }
      const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(!gmailUsernamePattern.test(formValues.email)){
        setInlineError((prevState) => ({
          ...prevState,
          emailerror: 'Invalid Email Address',
        }));
        return;
      }else{
        setInlineError((prevState) => ({
          ...prevState,
          emailerror: '',
        }));
      }
      const data = {
        name: formValues.name,
        email: formValues.email,
        mobile:mobileChange,
        mcc_id: mccId,
        country_id: formValues.country.value,
        password: formValues.passwordnew,
        confirmpassword:formValues.passwordcurrent,
        verify: 'email',
       }
      try{
        setIsLoading4(true);
         const response = await axiosInstance.post(signupapiurl,data);
         if(response.status === 200){
          setOtp('');
          setShow1(true);
          setIsLoading4(false);
          // startCountdown();
         }
      }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }finally{
        setIsLoading4(false);
      }
    }
    const resendotp = () => {

    }
    const checkverfiy = useCallback(()=> {
      const valid = (
        otp !== ''
      )
      setEnabledverify(valid);
    },[otp])
    useEffect(()=>{
      checkverfiy();
    },[checkverfiy]);
    const signupverify = async() => {
      const data = {
        otp : otp,
        device_type: 'web'
      }
       try{
        setIsLoading4(true);
          const response = await axiosInstance.post(signupverifyapiurl,data);
          if(response.status === 200){
            setOtp('');
            let X_AuthUser = response.data.data.authuser;
            const params = process_params();
          if (params.redirecturl) {
            const regex = /\/u\/([^/]+)/; 
            const matchall = params.allparams;
            const match1 = matchall.replace("redirecturl=", '');
            const url = match1.replace("&",'?');
            const match = url.match(regex); 
            if(match){
              const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
              window.location.href = updatedPathname;
            }
            // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
            //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
            // }
            else{
              const urlObj = new URL(url);
              urlObj.searchParams.set('x_authuser', X_AuthUser);
              window.location.href = urlObj.toString();
            }
          } else {
            window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
          }
          setIsLoading4(false);
          }
       }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
       }finally{
        setIsLoading4(false);
       }
    }
    const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      window.open('https://evzone.app/', '_blank', 'noopener,noreferrer');
    };
  return (
    <>
      
    <input type="text" id='name' className={`form-control logininputsign mt-3 ${formValues.name ? 'logininputsign-active' : ''}`} onKeyPress={handleKeyPress1} value={formValues.name} onChange={(e)=>{setError422('');setFormValues(prev => ({ ...prev, name: e.target.value }))}} placeholder="Name"></input>
    {inlineerror.nameerror && <div className="errors">{inlineerror.nameerror}</div>}
    <Select  options={formValues.countryoption} inputId='country'  value={formValues.country} isLoading={formValues.countryloading}  isSearchable={true} placeholder="Select Country" onChange={handlecountry} styles={customStyles1(Boolean(formValues.country))} />
    <div className="signupdivsplit">
            <div className="mt-2" style={{width:'100%',maxWidth:'446px'}}>  
            <input type="email" id='email' value={formValues.email} onChange={(e)=>{setFormValues((prevState) => ({...prevState,email: e.target.value,}));setInlineError((prevState) => ({...prevState,emailerror: '',}));setError422('')}} className={`form-control logininputsign ${formValues.email ? 'logininputsign-active' : ''}`}  placeholder="Email"></input>
            {inlineerror.emailerror && <div className="errors">{inlineerror.emailerror}</div>}
            </div>
            <div className="mt-2" style={{ position: 'relative', display: 'flex', alignItems: 'center',width:'100%',maxWidth:'446px', }}>
            <div style={{ position: 'absolute', left: 0, }}> 
            <Select options={countryOptions} inputId='mobile' onChange={handleCountryChangesign} value={countryOptions.find(option => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading} styles={countrystyle(selwidth - 35,menuHeight,'none',)}/>
            </div>
            <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 4px', position: 'absolute', left: `${selwidth - 30}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
             {/* <p style={{ position: 'absolute', left:'40px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{formValues.dial_code}</p> */}
            <input type="text"  className={`form-control logininputsign ${mobileChange ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{width: '100%',paddingLeft:`${selwidth - 15}px`}} placeholder="Mobile Number" value={mobileChange} name="mobile"  onKeyPress={handleKeyPresssign} onChange={(e) => handlePhoneNumberChangesign(e.target.value)} />
            </div>
            </div>
            {inlineerror.mobileerror && <div className="errors">{inlineerror.mobileerror}</div>}
            <div className="signupdivsplit">
            <div className=" mt-2 " style={{position:'relative',width:'100%',maxWidth:'446px'}}>
            <input type={showPasswordnew ? 'text' : 'password'} id='password' value={formValues.passwordnew} onFocus={handleFocus} onBlur={handleBlur}  onChange={handlepasswordchange} className={`form-control logininputsign ${formValues.passwordnew ? 'logininputsign-active' : ''}`}  
                placeholder="Password" name="password"  />
                {isFocused && (
                <div id="message4" className={`password-message ${isFocused ? "initial-focus" : ""}`}>
                    <h6>Password must contain the following:</h6>
                    <p className={getPasswordValidationClass(isInvalid.letter)}>
                        At least a Lowercase
                    </p>
                    <p className={getPasswordValidationClass(isInvalid.capital)}>
                        At least a Capital (Uppercase)
                    </p>
                    <p className={getPasswordValidationClass(isInvalid.special)}>
                        At least Special Character
                    </p>
                    <p className={getPasswordValidationClass(isInvalid.number)}>A number</p>
                    <p className={getPasswordValidationClass(isInvalid.length)}>
                        Minimum 8 Characters
                    </p>
                </div>
                )}
              <p className="toggle-password" onClick={togglePasswordVisibilitynew}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                {showPasswordnew ? (
                  formValues.passwordnew ? (
                    <img src={eye} className='eye'style={{width:'16px',height:'auto'}} alt="Show Password" />
                  ) : (
                    <img src={eyegrey} className='eye'style={{width:'16px',height:'auto'}} alt="Show Password" />
                  )
                ) : (
                  formValues.passwordnew ? (
                  <img src={eyeslash} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  ) : (
                    <img src={eyeslashgrey} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  )
                )}
              </p>
              {inlineerror.passworderror && <div className="errors">{inlineerror.passworderror}</div>}
            </div>
            <div style={{width:'100%',maxWidth:'446px'}}>
        <div className="mt-2" style={{position:'relative'}}>
            <input type={showPasswordcur ? 'text' : 'password'} id='confirmpassword' value={formValues.passwordcurrent} onChange={(e)=>{setFormValues((prev)=>({...prev,passwordcurrent:e.target.value}));setInlineError((prevState) => ({...prevState,passwordcurrenterror: '',}));setError422('')}} className={`form-control logininputsign ${formValues.passwordcurrent ? 'logininputsign-active' :''}`} 
                placeholder="Confirm Password" name="password"  />
            <p className="toggle-password" onClick={togglePasswordVisibilitycur}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                {showPasswordcur ? (
                  formValues.passwordcurrent ? (
                  <img src={eye} className='eye' style={{width:'16px',height:'auto'}} alt="Show Password" />
                  ) : (
                    <img src={eyegrey} className='eye' style={{width:'16px',height:'auto'}} alt="Show Password" />
                  )
                ) : (
                  formValues.passwordcurrent ? (
                  <img src={eyeslash} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  ) : (
                  <img src={eyeslashgrey} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  )
                )}
            </p>
            </div>
             {inlineerror.passwordcurrenterror && <div className="errors">{inlineerror.passwordcurrenterror}</div>}
        </div>
            </div>
            <input type="text" className="form-control logininputsign mt-2" placeholder="Referral Code"></input>
            <div className="mt-2" style={{width:'100%',display:'flex',alignItems:'center',maxWidth:'446px',gap:'5px'}}>
            <input type="checkbox" checked={ischecked}
                      onChange={handleCheckboxChange}></input>
            {/* <p className="singupterms" style={{cursor:'pointer'}} onClick={handleClick}>You must agree to our privacy and terms of use</p> */}
            <p className="singupterms">i agree to the <strong onClick={handleClick} style={{color:'#0897FF',textDecoration:'underline',cursor:'pointer'}}>Privacy & Terms</strong> <img src={expand} style={{cursor:'pointer'}} onClick={handleClick} alt='expand' width='15px'></img></p>
            </div>
            {error422 && (<div className='errors'>{error422}</div>)}
            <button style={{backgroundColor:enabledsignup ? '#F77F00' : '#EAEAEA',color:enabledsignup ? 'white' : 'hsl(0, 0%, 20%)',cursor:enabledsignup ? 'pointer' : 'not-allowed'}} disabled={!enabledsignup} onClick={signup} className="loginsubmit mt-2">Sign up</button>

            
     <Modal show={show1} dialogClassName="document-dialog1signup" contentClassName="document-content1signup"  centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
            <div className='d-flex justify-content-center flex-column align-items-center'>
            <h6 className='resetpassh6'>Enter Your OTP</h6>
            <p className='resetpassp resetpassp1'>Enter the 6-didit code sent<br></br>
            to {getMaskedEmail(formValues.email)}<br></br>
            Did not receive OTP ?<strong className={`${countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={countdown <= 0 ? resendotp : null}>Resend OTP</strong></p>
            <input type='text' value={otp} onKeyDown={handleKeyDown} onChange={(e)=>{setOtp(e.target.value);setError422('')}} placeholder='Enter OTP' className={`form-control mt-3 logininputsign ${otp ? 'logininputsign-active' : ''}`}></input>
            <button type="button" className='resendotp mt-4'> {countdown > 0 ? `${countdown}S` : `${countdown}S`}</button>
            {error422 && (<div className='errors'>{error422}</div>)}
            <button style={{backgroundColor:enabledverify ? '#F77F00' : '#EAEAEA',color:enabledverify ? 'white' : 'hsl(0, 0%, 20%)',cursor:enabledverify ? 'pointer' : 'not-allowed'}} disabled={!enabledverify} onClick={signupverify} className="loginsubmit mt-3">Submit</button>
            </div>
        </Modal.Body>
        </Modal>  
    </>
  )
})

export default WithToast(Signup);