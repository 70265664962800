import React,{ memo, useCallback, useEffect, useState } from 'react';
import resetpasswordotp from '../../images/Untitled-1-03.png';
import OtpInput from 'react-otp-input';
import { getMaskedEmail, getMaskedPhoneNumber, handleApiError } from '../../api/utils/utils';
import WithToast from '../../api/hooks/withtoasthook';
import axiosInstance from '../../api/utils/axiosutils';
import { forgotpasswordotpverifyapi } from '../../api/helper/urlhelper';
import { useNavigate } from 'react-router-dom';
const forgotpasswordotpverifyapiurl = forgotpasswordotpverifyapi();
const Forgototppage = memo(({formValues,setIsLoading4,setShowforgotpage,setShowforgotpageotp,setShowforgotpagepassword,showErrorToast}) => {
    const navigate = useNavigate();
    const [error422,setError422] = useState('');
    const [otpState, setOtpState] = useState({
        otp: '',
        countdown: 60, 
      });
      const [isSubmitting, setIsSubmitting] = useState(false);
      const handleChange = (otp) => {
        setError422('');
        setOtpState(prevState => ({ ...prevState, otp }));
        if (otp.length === 6 && !isSubmitting) {
            setIsSubmitting(true); // Set flag to true to prevent further submissions
          }
      };      
      const handleInputChangeotp = (e) => {
        const isNumber = /^\d*$/.test(e.key);
        if (!isNumber) {
          e.preventDefault();
        }
      };
    
      const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text/plain');
        const isNumeric = /^\d*$/.test(pastedText);
    
        if (!isNumeric) {
          e.preventDefault();
        } else {
          setOtpState((prev) => ({
            ...prev, otp: pastedText
          }));
        }
      };
      const resendotp = () => {

      }  
      const otpverify = useCallback(async() => {
        const data = {
           otp:otpState.otp
        }
        try{
            setIsLoading4(true);
           const response = await axiosInstance.post(forgotpasswordotpverifyapiurl,data);
           if(response.status === 200){
            setShowforgotpage(false);
            setShowforgotpageotp(false);
            setShowforgotpagepassword(true);
            setIsLoading4(false);
           }
        }catch(error){
          if(error.response){
            if(error.response.status === 422){
             const error_code = error.response.data.code;
             if (error_code === 'otp_retry') {
               navigate('/');
               setIsLoading4(false);
             }else{
               handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
             }
            } else{
             handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
            }
         }else{
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
         }
        }
      },[otpState.otp,setIsLoading4,showErrorToast,setShowforgotpage,setShowforgotpageotp,setShowforgotpagepassword,navigate]);
      useEffect(()=>{
        if(otpState.otp.length === 6 && isSubmitting){
            otpverify();
        }
      },[otpState.otp,otpverify,isSubmitting]);
  return (
    <>
      <div className='resetpasspad'>
      <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
      <img src={resetpasswordotp} alt='ressetpasslocak' className='resetppasslock '></img>
      <h6 className='mt-2 resetpassh6'>Enter OTP Code</h6>
      <p className='resetpassp resetpassp1'>
                Enter the 6-digit code sent<br />
                to <strong>{formValues.forgotvalueselect === 'Email' ? `${getMaskedEmail(formValues.forgotemail)}` : `${formValues.dial_code}${getMaskedPhoneNumber(formValues.forgotmobile)}`}</strong><br /> Did not receive OTP?
                <strong className={`${otpState.countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: otpState.countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={otpState.countdown <= 0 ? resendotp : null}> Resend OTP </strong>
              </p>
              <OtpInput
                value={otpState.otp}
                onChange={handleChange}
                numInputs={6}
                renderInput={(props, index) => (
                  <input {...props} onPaste={handlePaste} className={`custom-inputotp mt-4`} style={{ border: otpState.otp[index] ? '1px solid black' : '' }} onKeyPress={handleInputChangeotp} />
                )}
              />
              {error422 && <div className="errors mt-1">{error422}</div>}
              <div className='mt-3  d-flex justify-content-center w-100'>
                <button type="button" className='resendotp'>
                  {otpState.countdown > 0 ? `${otpState.countdown}S` : `${otpState.countdown}S`}
                </button>
              </div>
              <p className='mt-4 mb-4 custom_trouble'>Having any trouble?<strong className='custom_trouble_help'> Get Help</strong></p>
      </div> 
      </div> 
      
    </>
  )
})

export default WithToast(Forgototppage);