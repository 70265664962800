import { useState, useEffect, useCallback } from 'react';
import { calculateMenuHeight } from '../utils/utils';


const useMenuHeight = (offset = 200, maxHeight = 400) => {
  const [menuHeight, setMenuHeight] = useState(calculateMenuHeight(offset, maxHeight));

  const updateMenuHeight = useCallback(() => {
    setMenuHeight(calculateMenuHeight(offset, maxHeight));
  }, [offset, maxHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateMenuHeight);
    return () => window.removeEventListener('resize', updateMenuHeight);
  }, [updateMenuHeight]);

  return menuHeight;
};

export default useMenuHeight;