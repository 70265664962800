import React,{useState,useCallback,useRef,useEffect, memo} from 'react';
import WithToast from '../api/hooks/withtoasthook';
import { countrystyle, handleApiError, process_params, selectWidth, validateAndSetMobileChange } from '../api/utils/utils';
import {loginwithotpverifyapi,loginwithotpapi } from '../api/helper/urlhelper';
import axiosInstance from '../api/utils/axiosutils';
import Select, { components } from 'react-select';
import editimage from '../images/pencil-edit-button.svg';
import { useCountryData } from '../api/hooks/mobilehook';
import useMenuHeight from '../api/hooks/useMenuheight';
const loginwithotpverifyapiurl = loginwithotpverifyapi();
const loginwithotpapiurl = loginwithotpapi();
const Loginwithotp = memo(({showErrorToast,setShowForm1,setShowForm2,setShowForm3,toggleLoginForms,setIsLoading4,myaccountdomainurl})=>{
    const mobileInputRef = useRef(null);
    const inputRef = useRef(null);
    const [showCountryInput, setShowCountryInput] = useState(false);
    const [islogged,setIslogged] = useState(false);
    const [islogged1,setIslogged1] = useState(false);
    const [otpfieldfalse,setOtpfieldfalse] = useState(false);
    const [isCountryCodeFetched, setIsCountryCodeFetched] = useState(false);
    const [mobileChange, setMobileChange] = useState('');
    const [numberinvalid,setNumberinvalid] = useState(false);
    const {
      mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
      loadCountryByIp,
    } = useCountryData();
    const [formValues,setFormValues] = useState({
        countryOptions:[],country_code:'',mcc_id:'',dial_code:'',username:'',isLoading:false,otp:'',showPassword:false
    });
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
      setError422('');
    };  
    const [error,setError] = useState({
        usernameerror:'',passworderror:''
    })
    const [error422,setError422] = useState('');
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            login(e);
        }
    };
    useEffect(() => {
        if (showCountryInput) {
            mobileInputRef.current?.focus();
        } else {
            inputRef.current?.focus();
        }
    }, [showCountryInput]);
    const handleUsernameChange = async(e) => {
      let inputUsername = e.target.value.replace(/\s/g, '');
      if (/^\+?\d+$/.test(inputUsername)) {
          if (!isCountryCodeFetched) {
              setShowCountryInput(true);
              loadCountryByIp(true);
              setIsCountryCodeFetched(true); 
          }
          validateAndSetMobileChange(inputUsername, {
            setMobileChange: setMobileChange,
            setError:  (errorMsg) => setError((prev) => ({ ...prev, usernameerror: errorMsg })),
            setNumberInvalid: setNumberinvalid,
            mobile_length: mobile_length,             
            mobile_starting_digits: mobile_starting_digits 
          });
      } else {
          setShowCountryInput(false);
          setIsCountryCodeFetched(false);
          setMobileChange(inputUsername);
           setError((prev)=>({
          ...prev,usernameerror:'',passworderror:''
      }));
      }
      setError422('');
  };
  const handleCountryChange = (selectedOption) => {
    const selectedCountryData = countryOptions.find(
        (country) => country.value === selectedOption.value
    );
    if (selectedCountryData) {
      const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
  
      setMccId(mcc_id);
      setDialCode(`+${dial_code}`);
      setCountryCode(country_code);
      setMobile_length(mobile_length);
      setMobile_starting_digits(mobile_starting_digits);
  
      // Apply validation to existing mobile number
      validateAndSetMobileChange(mobileChange, {
        setMobileChange,
        setError: (errorMsg) => setError((prev) => ({ ...prev, usernameerror: errorMsg })),
        setNumberInvalid: setNumberinvalid,
        mobile_length,
        mobile_starting_digits,
      });
    }
};
      
    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
  
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
  
        const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
        if (numericPastedText.length > maxLength) {
            numericPastedText = numericPastedText.slice(0, maxLength);
        }
  
        setFormValues((prev)=>({
            ...prev,username:numericPastedText
        }));
        setError((prev)=>({
            ...prev,usernameerror:'',passworderror:''
        }));
        setError422('');
        e.preventDefault();
    };
    const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* {data.label} */}
        </div>
    );
    const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
    };
    const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
    const menuHeight = useMenuHeight(200, 400);
    useEffect(() => {
      setSelWidth(selectWidth(dialCode));
    }, [dialCode]);
    const toggleLoginForm = () => {
        toggleLoginForms();
        // setShowForm1(true);
        // setShowForm3(false);
        // setShowForm2(false);
    }
    const check = useCallback(()=>{
      const sy = /^\+?\d+$/.test(mobileChange);
      const valid = (
        mobileChange !== '' &&  (sy ? numberinvalid === true : true)
      )
     setIslogged(valid);
    },[mobileChange])
     useEffect(() => {
      check();
    },[check]);
    const check1 = useCallback(()=>{
      const valid = (
        formValues.otp !== ''
      )
     setIslogged1(valid);
    },[formValues.otp])
     useEffect(() => {
      check1();
    },[check1]);
    const handleKeyDown = (e) => {
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
      // Allow numeric keys and specific control keys
      if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
        e.preventDefault(); // Prevent the default action
        setError422('Please enter only numbers'); // Set error message
      } else {
        setError422(''); // Clear error if valid key is pressed
      }
    };
    const sendotp = async (event) => {
      event.preventDefault();
      
      const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let data;
      if (/^\+?\d+$/.test(mobileChange)) {
        if (!mccId) {
          setError((prev) => ({ ...prev, usernameerror: 'Select Your Country Again' }));
          return;
        }
        data = {
          username: mobileChange,
          mcc_id: mccId,
        };
      } else if (gmailUsernamePattern.test(mobileChange)) {
        data = {
          username: mobileChange,
        };
      } else {
        setError((prev) => ({ ...prev, usernameerror: 'Invalid Email Address' }));
        return;
      }
      try {
        setIsLoading4(true);
        const response = await axiosInstance.post(loginwithotpapiurl, data);
        if (response.status === 200) {
          setError422('');
          setFormValues((prev)=>({
            ...prev,otp:''
          }))
          setOtpfieldfalse(true);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            const X_AuthUser = error.response.data.data.authuser;
            const params = process_params();
            if (params.redirecturl) {
              const regex = /\/u\/([^/]+)/; 
              const matchall = params.allparams;
              const match1 = matchall.replace("redirecturl=", '');
              const url = match1.replace("&",'?');
              const match = url.match(regex); 
              if(match){
                const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
                window.location.href = updatedPathname;
              }
              // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
              //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
              // }
              else{
                const urlObj = new URL(url);
                urlObj.searchParams.set('X_AuthUser', X_AuthUser);
                window.location.href = urlObj.toString();
              }
            } else {
              window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
            }
          } else if (error.response.status === 401) {
            const error_code = error.response.data.code;
            if (error_code === 'auth_failed') {
              setIsLoading4(false);
              setError422(error.response.data.mobile_error);
            }
          }else {
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
          }
        } else {
          // Handle network errors or any other unexpected errors
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      } finally {
        setIsLoading4(false);
      }
    };
    const logwithootp = () => {
      setOtpfieldfalse(false);
    }
    const login = async (event) => {
      event.preventDefault();
      const data = {
        otp : formValues.otp,
        device_type :'web'
      }
      try {
        setIsLoading4(true);
        const response = await axiosInstance.post(loginwithotpverifyapiurl, data);
        if (response.status === 200) {
          const X_AuthUser = response.data.data.authuser;
          const params = process_params();
          if (params.redirecturl) {
            const regex = /\/u\/([^/]+)/; 
            const matchall = params.allparams;
            const match1 = matchall.replace("redirecturl=", '');
            const url = match1.replace("&",'?');
            const match = url.match(regex); 
            if(match){
              const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
              window.location.href = updatedPathname;
            }
            // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
            //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
            // }
            else{
              const urlObj = new URL(url);
              urlObj.searchParams.set('x_authuser', X_AuthUser);
              window.location.href = urlObj.toString();
            }
          } else {
            window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            const X_AuthUser = error.response.data.data.authuser;
            const params = process_params();
          if (params.redirecturl) {
            const regex = /\/u\/([^/]+)/; 
            const matchall = params.allparams;
            const match1 = matchall.replace("redirecturl=", '');
            const url = match1.replace("&",'?');
            const match = url.match(regex); 
            if(match){
              const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
              window.location.href = updatedPathname;
            }
            // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
            //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
            // }
            else{
              const urlObj = new URL(url);
              urlObj.searchParams.set('x_authuser', X_AuthUser);
              window.location.href = urlObj.toString();
            }
          } else {
            window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
          }
          } else if (error.response.status === 401) {
            const error_code = error.response.data.code;
            if (error_code === 'auth_failed') {
              setIsLoading4(false);
              setError422(error.response.data.mobile_error);
            }
          }else if(error.response.status === 422){
            const error_code = error.response.data.code;
            if (error_code === 'otp_retry') {
              setFormValues((prev)=>({
                ...prev,
                username:'',otp:'',
              }));
              setError422('');
                setOtpfieldfalse(false);
                setIsLoading4(false);  
            } 
            else{
              handleApiError(error, setIsLoading4, setError422, false, showErrorToast); 
            }
          } 
           else {
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
          }
        } else {
          // Handle network errors or any other unexpected errors
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      } finally {
        setIsLoading4(false);
      }
    };
  return (
    <>
      {!otpfieldfalse ? (
      <> 
      {!showCountryInput ? (
      <div className="logininputboxcenter"> 
            <input className={`form-control logininputsign mt-4 ${mobileChange ? 'logininputsign-active' : ''}`} value={mobileChange} ref={inputRef} onChange={handleUsernameChange} onKeyPress={handleKeyPress} placeholder="Email or Mobile" type="text"></input>
            <div style={{ position: 'absolute', right: '30px', top: '68%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
            <p onClick={toggleLoginForm} style={{ fontSize: '10px',marginBottom:'0px', fontWeight: "500", color:'#02CD8D'}}>Login with Password</p>
            </div>
      </div>
      ) : (
      <>
      <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center' ,width:'100%',maxWidth:'446px'}}>
          <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select options={countryOptions} onChange={handleCountryChange} value={countryOptions.find(option => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading} styles={countrystyle('82px',menuHeight)}/>
          </div>
          <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
          <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
          <input type="text" className={`form-control logininputsign ${mobileChange ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }} placeholder="Mobile Number" value={mobileChange} name="mobile"  onChange={handleUsernameChange}/>
          </div>
      </>
      )}
     {error.usernameerror && (<div className='errors'>{error.usernameerror}</div>)}
     {error422 && (<div className='errors'>{error422}</div>)}
     <button className="loginsubmit mt-4" style={{backgroundColor:islogged ? '#F77F00' : '#EAEAEA',color:islogged ? 'white' : 'hsl(0, 0%, 20%)',cursor:islogged ? 'pointer' : 'not-allowed'}} disabled={!islogged} onClick={sendotp}>Send OTP</button>
    </>
    ) : (
     <>
      {!showCountryInput ? (
      <div className="logininputboxcenter"> 
            <input className={`form-control logininputsign mt-4 ${mobileChange ? 'logininputsign-active' : ''}`} value={mobileChange} ref={inputRef} onChange={handleUsernameChange} onKeyPress={handleKeyPress} placeholder="Email or Mobile" type="text" readOnly></input>
            <div style={{ position: 'absolute', right: '30px', top: '66%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
             <img src={editimage} alt='editimage' className='editimage1' onClick={logwithootp}></img> 
            {/* <p onClick={toggleLoginForm} style={{ fontSize: '10px',marginBottom:'0px', fontWeight: "500", color:'#02CD8D'}}>Login with Password</p> */}
            </div>
      </div>
      ) : (
      <>
      <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center' ,width:'100%',maxWidth:'446px'}}>
          <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select isDisabled={true} options={countryOptions} onChange={handleCountryChange} value={countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading} styles={countrystyle('82px',menuHeight)}/>
          </div>
          <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
             <img src={editimage} alt='editimage' className='editimage1' onClick={logwithootp}></img> 
            {/* <p onClick={toggleLoginForm} style={{ fontSize: '10px',marginBottom:'0px', fontWeight: "500", color:'#02CD8D'}}>Login with Password</p> */}
            </div>
          <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
          <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
          <input type="text" className={`form-control logininputsign ${mobileChange ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }} placeholder="Mobile Number" value={mobileChange} name="mobile" onChange={handleUsernameChange} readOnly/>
          </div>
      </>
      )}
      <input type="text" value={formValues.otp} onChange={handleChange} onKeyDown={handleKeyDown}  maxLength={6} className={`form-control mt-3 logininputsign ${formValues.otp ? 'logininputsign-active' : ''}`} name='otp'  id="inputField" placeholder="Enter OTP" />
      {error422 && (<div className='errors'>{error422}</div>)}
     <button className="loginsubmit mt-4" style={{backgroundColor:islogged1 ? '#F77F00' : '#EAEAEA',color:islogged1 ? 'white' : 'hsl(0, 0%, 20%)',cursor:islogged1 ? 'pointer' : 'not-allowed'}} disabled={!islogged1} onClick={login}>Confirm</button>
     </> 
    )}
    </>
  )
})

export default WithToast(Loginwithotp);