import React from 'react';
import logo from '../images/evzone logo-04.webp';
function Navbar() {
  return (
   <>
    <div className='account_nav'>
        <img src={logo} alt='nav_logo' width={180} height={60} className='nav_logo_auth'></img>
    </div>
   </>
  )
}

export default Navbar;