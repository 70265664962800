import React, { useEffect, useState } from 'react';
import Logincombinecomponents from './authpages/logincombinecomponents';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Forgotpage from './authpages/forgotpage/forgotpage';
import 'bootstrap/dist/css/bootstrap.min.css';
import Listaccounts from './components/listaccounts';
import Loader from './components/loader';
import Navbar from './components/navbar';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const ShouldDisplayNavbar = () => {
  const location = useLocation(); 
  const noNavbarRoutes = ['/auth']; // Routes where Navbar should NOT appear
  return !noNavbarRoutes.includes(location.pathname); // Return true if the route is not in the excluded list
};
function App() {
  const [isLoading4, setIsLoading4] = useState(false);
 // Move useLocation to App component
return (
    <>
      {isLoading4 && <Loader />}  
      <Router>
        <ScrollToTop />
        {/* Conditionally render the Navbar */}
        {<ShouldDisplayNavbar/> && (
          <div className='container-fluid forgot_nav_heigth'>
            <Navbar />
          </div>
        )}
        <Routes>
          <Route exact path='/' element={<Listaccounts setIsLoading4={setIsLoading4} />} />
          <Route path='/auth/forgot' element={<Forgotpage setIsLoading4={setIsLoading4} />} />
          <Route  path='/auth' element={<Logincombinecomponents setIsLoading4={setIsLoading4} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
